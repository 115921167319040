<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2024
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            active
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                Base de Datos
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            Base de Datos
          </h2>

          <b-embed
            type="video"
            aspect="16by9"
            controls
          >
            <source
              src="https://ayuda.ine.mx/2024/informate/assets/video/Video_Base_de_Datos.mp4"
              type="video/mp4"
            >
          </b-embed>
          <br />

          <p>
            La Base de Datos se refiere al conjunto de información perteneciente al Programa de
            Resultados Electorales Preliminares, ordenada de forma sistemática para su descarga
            y análisis. Es actualizada conforme al último corte. Permite realizar distintas
            operaciones ya que incluye datos numéricos, alfanuméricos y cronológicos.
          </p>

          <p>
            Si deseas una revisión detallada de las
            variables de la Base de Datos descarga el Manual.
          </p>

          <div  class="row p-contenedor-30 m-t-50 m-b-50">
            <div  class="col-lg-12 text-center">
                <a
                  href="https://ayuda.ine.mx/2024/informate/assets/docs/Prep/Base_de_Datos/Manual_Base_Datos.pdf"
                  target="_blank"
                  class="btn btn-primary mx-auto d-block"
                >
                  Descarga Infografía
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid-tiempo {
  display: grid;
  align-items: center;
  grid-template-columns: 25% 1fr;
  color: white;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }

  .grid-interno {
    display: grid;
    align-items: center;
    gap: 45px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &:nth-child(odd) {
    background-color: #766CAE;

    .detalle-block {
      background-color: #5C5688;
    }

    .descripcion-block {
      background-color: #4C4871;
    }
  }

  &:nth-child(even) {
    background-color: #E50077;

    .detalle-block {
      background-color: #962464;
    }

    .descripcion-block {
      background-color: #6B1046;
    }
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}

.row {
  padding-bottom: 15px;
  padding-top: 15px;
}
</style>
